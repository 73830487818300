<template>
  <div class="detail-content">
    <div class="detail-title">
      <h2>{{ supplyDetail.title }}</h2>
    </div>
    <div class="content">
      <span v-html="supplyDetail.info"></span>
    </div>
    <div class="detail-time">
      <!--时间-->
      <span v-if="supplyDetail.order_sn != null"
        >{{ supplyDetail.order_sn.substr(0, 4) }}-{{ supplyDetail.order_sn.substr(4, 2) }}-{{
          supplyDetail.order_sn.substr(6, 2)
        }}
        &nbsp;{{ supplyDetail.order_sn.substr(8, 2) }}:{{ supplyDetail.order_sn.substr(10, 2) }} &nbsp;</span
      >
      <i class="fas fa-map-marker-alt"></i>
      <span class="center-span-position">
        {{ supplyDetail.address }}
      </span>
    </div>
    <div class="detail-line"></div>
  </div>
</template>

<script>
export default {
  name: 'DetailContent',
  props: {
    supplyDetail: {
      type: Object
    }
  }
}
</script>

<style scoped>
.detail-content {
  padding: 10px 10px;
}
.detail-title {
  margin: 10px 0;
  font-size: 16px;
}
.content {
  font-size: 14px;
  color: rgb(50, 50, 50);
}
.content span {
  line-height: 25px;
}
.detail-time {
  margin: 15px 0;
  font-size: 12px;
  color: rgb(150, 150, 150);
}
.detail-line {
  width: 100%;
  height: 1px;
  background-color: rgba(150, 150, 150, 0.3);
}
</style>
