<template>
  <div class="detail-com-input">
    <div class="com-input">
      <el-input
        ref="inputComment"
        type="textarea"
        v-model="content"
        maxlength="1000"
        placeholder="❤评论是真爱"
        show-word-limit
        :autosize="{ minRows: 1, maxRows: 6 }"
        size="mini"
        :style="{ width: $store.state.screenWidth - 90 + 'px' }"
      >
      </el-input>
      <span style="margin-left:5px" @click="btnsendout()">发送</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailComInput',
  props: {
    supplyId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      userId: null,
      content: '',
      comment_time: '2021-06-28 19:34:32'
    }
  },
  created() {
    this.userId = window.sessionStorage.getItem('userId')
  },
  computed: {
    /*获取时间*/
    getTime() {
      var date = new Date()
      var seperator1 = '-'
      var seperator2 = ':'
      //以下代码依次是获取当前时间的年月日时分秒
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      var minute = date.getMinutes()
      var hour = date.getHours()
      var second = date.getSeconds()
      //固定时间格式
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      if (hour >= 0 && hour <= 9) {
        hour = '0' + hour
      }
      if (minute >= 0 && minute <= 9) {
        minute = '0' + minute
      }
      if (second >= 0 && second <= 9) {
        second = '0' + second
      }
      var currentdate =
        year + seperator1 + month + seperator1 + strDate + ' ' + hour + seperator2 + minute + seperator2 + second
      return currentdate
    }
  },
  components: {},
  methods: {
    btnsendout() {
      this.$emit('btnsend', this.content)
    },
    inputCommentFocus() {
      this.$refs.inputComment.focus()
    }
  }
  /*
   * 登录id
   * 详情id
   * 输入评论
   * 当前时间
   * */
}
</script>

<style scoped>
.detail-com-input {
}
.com-input {
  padding: 10px 15px;

  display: flex;
  align-items: center;
}
.com-input input {
  border: 0;
  background-color: rgba(235, 235, 235, 0.6);
  /*光标颜色*/
  caret-color: #1bc4fb;
  /*选中后*/
  outline: 0;
  color: #000;
  /*高度*/
  padding: 9px;
  width: 43%;
  border-radius: 20px;
  font-size: 13px;
  margin-right: 6px;
}
.com-input input::placeholder {
  color: rgb(90, 90, 90);
  font-size: 14px;
}

.com-input span {
  background-color: #5e94ff;
  color: #fff;
  padding: 7px 12px;
  border-radius: 15px;
  font-size: 14px;
}

/* 覆盖掉el组件中的背景色，添加圆角，去掉边框 */
.el-textarea /deep/ textarea.el-textarea__inner {
  height: 32px;
  border-radius: 15px;
  background-color: rgba(235, 235, 235, 0.6);
  border: none;
  border-radius: 30px;
}

/* 覆盖掉el组件中的背景色 */
.el-textarea /deep/ span.el-input__count {
  background-color: rgba(0, 0, 0, 0);
}
</style>
