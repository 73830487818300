<template>
  <div class="toast" v-show="show">
    <div>{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: {
      type: String
    },
    show: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.toast {
  font-size: 14px;
  position: fixed;
  width: 140px;
  height: 30px;
  line-height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(10, 10, 10, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  z-index: 5;
}
</style>
