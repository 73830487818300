<template>
  <div class="comment-bar">
    <div class="comment-left left">
      <input placeholder="说点什么..." @click="btninput" />
    </div>
    <div class="comment-right right">
      <div>
        <i v-if="supplyDetail.is_zan == 0" class="iconfont icon-heart" @click="btnheart(0)"></i>
        <i v-if="supplyDetail.is_zan == 1" class="iconfont icon-aixin" @click="btnheart(1)"></i>
        <span>{{ supplyDetail.zan }}</span
        ><!-- 点赞次数 -->
      </div>

      <div>
        <i v-if="supplyDetail.is_attention == 0" class="iconfont icon-xing" @click="btnxing(0)"></i>
        <i v-if="supplyDetail.is_attention == 1" class="iconfont icon-xingbiao" @click="btnxing(1)"></i>
        <span>{{ supplyDetail.attention }}</span
        ><!-- 收藏次数 -->
      </div>

      <div>
        <i class="iconfont icon-xiaoxi1"></i>
        <span v-if="commentList">{{ commentList.length }}</span>
      </div>
    </div>

    <toast :message="message" :show="show"></toast>
  </div>
</template>

<script>
import { getCollections } from 'network/user'
import { zan_trade } from 'network/fabs'
import Toast from 'components/toast/Toast'
import { addfollow } from 'network/follow'

export default {
  name: 'DetailCommentBar',
  components: {
    Toast
  },
  props: {
    id: {
      type: [String, Number]
    },
    supplyDetail: {
      type: Object
    },
    commentList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      fabslist: [],
      collectionslist: [],
      user_id: null,
      x: 0,
      y: 0,
      message: '',
      show: false
    }
  },
  created() {
    this.user_id = window.sessionStorage.getItem('userId')
    /*根据用户id获取点赞*/
    // this.getFabs()
    /*根据用户id获取点赞*/
    // this.getCollections()
  },
  methods: {
    btninput() {
      this.$emit('btninput')
    },

    /*获取收藏*/
    getCollections() {
      getCollections(this.user_id).then((res) => {
        this.collectionslist = res.data.collectionsList
        for (let i = 0; i < this.collectionslist.length; i++) {
          if (this.id == this.collectionslist[i].gu_id) {
            this.y++
          }
        }
      })
    },

    /*点赞*/
    btnheart(type) {
      var that = this
      var message
      /**
       * 判断点赞过后就只能取消
       */
      zan_trade(this.id, type).then((res) => {
        if (res.data.status == 1) {
          /*更新点赞数量*/
          if (type == 0) {
            that.supplyDetail.zan = that.supplyDetail.zan + 1
            that.supplyDetail.is_zan = 1
            message = '点赞成功'
          } else {
            that.supplyDetail.zan = that.supplyDetail.zan - 1
            that.supplyDetail.is_zan = 0
            message = '取消点赞成功'
          }
          this.$message(message)
        } else {
          this.$message('网络错误!')
        }
      })
    },
    /*点赞*/
    btnxing(type) {
      var that = this
      var message
      /**
       * 判断点赞过后就只能取消
       */
      addfollow(this.id, type).then((res) => {
        if (res.data.status == 1) {
          /*更新点赞数量*/
          if (type == 0) {
            that.supplyDetail.attention = that.supplyDetail.attention + 1
            that.supplyDetail.is_attention = 1
            message = '关注成功'
          } else {
            that.supplyDetail.attention = that.supplyDetail.attention - 1
            that.supplyDetail.is_attention = 0
            message = '取消关注成功'
          }
          this.$message(message)
        } else {
          this.$message('网络错误!')
        }
      })
    }
  }
}
</script>

<style scoped>
.comment-bar {
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 49px;
}
.left {
  width: 45%;
}
.right {
  width: 55%;
}
.comment-left {
  margin-left: 8px;
}
.comment-left input {
  border: 0;
  background-color: rgba(220, 220, 220, 0.6);
  /*光标颜色*/
  caret-color: #1bc4fb;
  /*选中后*/
  outline: 0;
  color: #000;
  /*高度*/
  padding: 9px;
  width: 85%;
  border-radius: 20px;
  font-size: 13px;
}

.comment-left input::placeholder {
  color: rgb(90, 90, 90);
  font-size: 14px;
}

.comment-right {
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 5px;
}

.comment-right div {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.icon-heart {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}
.icon-xing {
  font-size: 19px;
  font-weight: 500;
}
.icon-xiaoxi1 {
  font-size: 20px;
  font-weight: 500;
}
.comment-right i {
  margin-right: 3px;
}
.comment-right span {
  font-size: 15px;
}
.icon-aixin {
  font-size: 21px;
  font-weight: 500;
  color: red;
}
.icon-xingbiao {
  font-size: 23px;
  font-weight: 500;
  color: red;
}
</style>
