<template>
  <!--v-if-->
  <div class="home-detail">
    <!--详情头部导航-->
    <nav-bar class="nav-detail">
      <div slot="left" class="nav-left" @click="btnleft">
        <i class="fas fa-angle-left"></i>
      </div>

      <div slot="center" class="nav-center">
        <div>
          <img
            v-if="supplyDetail.fbr != null"
            @click="btnpersonal"
            :src="supplyDetail.fbr.img"
            style="width:24px;height:24px"
          />
          <div class="center-span">
            <span v-if="supplyDetail.fbr != null" class="center-span-name" style="line-height: 14px;">
             {{ supplyDetail.fbr.name }}
            </span>
          </div>
        </div>
      </div>

      <div slot="right" class="nav-right">
        <div>
          <span
            v-if="supplyDetail.is_store_collect == 0"
            class="nav-right-span"
            id="nav-right-span"
            @click="btnfollow(0)"
            >关注</span
          >
          <span v-if="supplyDetail.is_store_collect == 1" class="nav-right-al" id="nav-right-span" @click="btnfollow(1)"
            >取关</span
          >
        </div>
      </div>
    </nav-bar>
    <!--中部轮播图-->
    <detail-image :supplyDetail="supplyDetail"></detail-image>
    <!--中部内容-->
    <detail-content :supplyDetail="supplyDetail"></detail-content>

    <!--评论-->
    <detail-comment
      v-if="supplyDetail.status == 1"
      :commentList="commentList"
      :id="id"
      @btninput="btninput"
    ></detail-comment>

    <!--评论输入框-->
    <detail-com-input
      v-if="supplyDetail.status == 1"
      ref="detailInput"
      @btnsend="btnsend"
      :supplyId="id"
      class="home-detail-input"
      id="home-detail-input"
    ></detail-com-input>

    <!--输入框背景-->
    <div class="input-back" id="input-back" @click="btninputback"></div>

    <!--弹框-->
    <toast :message="message" :show="show"></toast>

    <!--底部评论导航-->
    <detail-comment-bar
      v-if="supplyDetail.status == 1"
      :supplyDetail="supplyDetail"
      :commentList="commentList"
      :id="id"
      class="comment-bar"
      @btninput="btninput"
    ></detail-comment-bar>

    <!--填充-->
    <div class="detail-bottom"></div>

    <!-- <user-other @btnoutother="btnoutother" class="user-other" id="user-other" :userlist="userlist" :user_id="userId"></user-other> -->
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import DetailImage from './detailcom/DetailImage'
import DetailContent from './detailcom/DetailContent'
import DetailComment from './detailcom/DetailComment'
import DetailComInput from './detailcom/DetailComInput'
import Toast from 'components/toast/Toast'
// import UserOther from "../../../user/userother/UserOther";
import DetailCommentBar from './detailcom/DetailCommentBar'

import { getSupplyDetail, getCommentList, addComment } from 'network/home'
import { getUserFollow, followUser } from 'network/follow'
export default {
  name: 'HomeDetail',
  components: {
    NavBar,
    DetailImage,
    DetailContent,
    DetailComment,
    DetailComInput,
    Toast,
    // UserOther,
    DetailCommentBar
  },
  data() {
    return {
      id: null,
      replyID: 0,
      supplyDetail: {},
      commentList: [],
      detailcomment: {},
      user_id: null,
      userfollowlist: [],
      message: '关注成功',
      show: false,
      userlist: {},
      userId: null
    }
  },
  created() {
    /*获取id*/
    this.id = this.$route.params.id

    /*获取用户id*/
    this.user_id = window.sessionStorage.getItem('userId')

    /*获取用户关注*/
    // this.getUserFollow();
    /*获取详情数据*/
    this.getSupplyDetail()
  },
  updated() {
    for (let i = 0; i < this.userfollowlist.length; i++) {
      // if(this.supplyDetail.guide.userId===this.userfollowlist[i].fol_id){
      //     this.$store.state.bool=true
      //     break;
      // }
    }

    if (this.$store.state.bool === true) {
      let navrightspan = document.getElementById('nav-right-span')
      navrightspan.style.display = 'none'

      let navrightal = document.getElementById('nav-right-al')
      navrightal.style.display = 'block'
    }

    if (this.$store.state.bool === false) {
      let navrightspan = document.getElementById('nav-right-span')
      navrightspan.style.display = 'block'

      let navrightal = document.getElementById('nav-right-al')
      if (navrightal) {
        navrightal.style.display = 'none'
      }
    }

    // let noteother=document.getElementById("note-other")
    // let fabsother=document.getElementById("fabs-other")
    // noteother.style.display='none'
    //fabsother.style.display='none'
  },
  mounted() {},
  methods: {
    /*返回*/
    btnleft() {
      this.$store.state.bool = false
      //   this.$router.push('/home')
      this.$router.back()
      //this.$router.go(-1)
    },

    /*添加/取消关注*/
    btnfollow(type) {
      var that = this
      var message
      /**
       * 判断关注过后就只能取消
       */
      followUser(that.supplyDetail.store_id, type).then((res) => {
        if (res.data.status == 1) {
          /*更新点赞数量*/
          if (type == 0) {
            that.supplyDetail.is_store_collect = 1
            this.$store.state.bool = !this.$store.state.bool
            this.$store.state.bool = !this.$store.state.bool
            message = '关注成功'
          } else {
            that.supplyDetail.is_store_collect = 0
            message = '取消关注成功'
          }
          this.$message(message)
        } else {
          this.$message('网络错误!')
        }
      })
    },
    /*添加关注*/
    // btnaddfollow(){
    //     addfollow(this.id,0).then()
    //     this.$store.state.bool=!this.$store.state.bool;
    //     this.show=true
    //     setTimeout(()=>{
    //         this.show=false
    //     },1000)
    // },

    // /*取消关注*/
    // btndeletefollow(){
    //     addfollow(this.id,1).then()
    //     this.$store.state.bool=!this.$store.state.bool;
    //     let navrightspan=document.getElementById("nav-right-span");
    //     navrightspan.style.display='block'
    //     let navrightal=document.getElementById("nav-right-al");
    //     navrightal.style.display='none'
    //     this.$router.go(0)
    //     this.message='取消成功'
    //     this.show=true
    //     setTimeout(()=>{
    //         this.show=false
    //     },1000)
    // },
    /*获取用户关注*/
    getUserFollow() {
      getUserFollow(this.user_id).then((res) => {
        this.userfollowlist = res.data.followList
      })
    },

    /*获取详情数据*/
    getSupplyDetail() {
      getSupplyDetail(this.id).then((res) => {
        this.supplyDetail = res.data.result

        /*获取评论*/
        this.getCommentList()
      })
    },

    /*获取评论信息*/
    getCommentList() {
      getCommentList(this.id).then((res) => {
        this.commentList = res.data.result
      })
    },
    /*评论输入框
     */
    btninput(replyID) {
      this.replyID = replyID
      let input = document.getElementById('home-detail-input')
      let inputback = document.getElementById('input-back')
      this.$refs.detailInput.inputCommentFocus();
      
      inputback.style.display = 'block'
      input.style.bottom = 0 + 'px'
    },

    btninputback() {
      let input = document.getElementById('home-detail-input')
      let inputback = document.getElementById('input-back')
      inputback.style.display = 'none'
      input.style.bottom = -301 + 'px'
    },

    btnsend(content) {
      var c_id = this.replyID
      addComment(this.id, c_id, content).then((res) => {
        if (res.data.status == 1) {
          //this.$router.go(0)
          this.$message('评论已提交成功，审核后可见')
          let input = document.getElementById('home-detail-input')
          let inputback = document.getElementById('input-back')
          inputback.style.display = 'none'
          input.style.bottom = -301 + 'px'
        } else {
          this.$message('网络错误，请重新提交!')
        }
      })
    },

    /*单击头像个人中心*/
    btnpersonal() {
      let userother = document.getElementById('user-other')
      // let noteother=document.getElementById("note-other")
      //let fabsother=document.getElementById("fabs-other")
      // noteother.style.display='block'
      //fabsother.style.display='block'
      userother.style.left = 0 + 'px'
    },
    /*返回*/
    btnoutother() {
      let userother = document.getElementById('user-other')
      userother.style.left = 100 + '%'
      // let noteother=document.getElementById("note-other")
      //let fabsother=document.getElementById("fabs-other")
      // noteother.style.display='none'
      //fabsother.style.display='none'
    }
  }
}
</script>

<style scoped>
.home-detail {
  position: relative;
  z-index: 5;
  background-color: #fff;
}
.nav-detail {
  position: sticky;
  top: 0;
  height: 52px;
  align-items: center;
  background-color: #fff;
}
.nav-left {
  text-align: center;
  font-size: 24px;
  margin-right: 15px;
}
.nav-center div {
  display: flex;
  text-align: center;
  align-items: center;
}
.nav-center img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
}
.center-span {
  display: flex;
}
.center-span i {
  font-size: 12px;
  color: rgb(120, 120, 120);
  margin-right: 3px;
}
.center-span-name {
  font-size: 12px;
  margin-right: 15px;
}
.center-span-position {
  font-size: 12px;
  color: rgb(120, 120, 120);
}

.nav-right {
  text-align: center;
  width: 50px;
  height: 25px;
}

.nav-right-span {
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  border: 1px solid #ff4500;
  border-radius: 15px;
  color: #ff4500;
}

.nav-right-al {
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  border: 1px solid rgba(120, 120, 120, 0.4);
  border-radius: 18px;
  color: rgba(100, 100, 100, 0.6);
}

/*底部评论导航*/
.comment-bar {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  border-top: 0.001rem solid rgba(150, 150, 150, 0.2);
  background-color: #fff;
}

.detail-bottom {
  width: 100%;
  height: 49px;
}

/*评论输入框*/
.home-detail-input {
  position: fixed;
  height: 150px;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: -301px;
  transition: bottom 0.4s;
}

.input-back {
  position: fixed;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(40, 40, 40, 0.2);
  z-index: 9;
  transition: all 0.4s;
}

.user-other {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 20;
  transition: left 0.5s;
}
.active {
  background-color: red;
}
/**.nav-bar：组件标签名称， div：元素节点 left：要修改的样式*/
.nav-bar /deep/ div.left {
  width: 10%;
}
.nav-bar /deep/ div.right {
  width: 20%;
}
</style>
