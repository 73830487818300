<template>
  <div class="detail-comment">
    <div class="comment-count" v-if="Object.keys(commentList).length !== 0">
      <span v-if="commentList != null">共{{ commentList.length }}条评论</span>
    </div>

    <!--评论-->
    <div class="comment-item" v-for="item in commentList" :key="item.id">
      <div class="comment-user">
        <img :src="item.head_pic" />
        <span>{{ item.nickname }}</span>
      </div>

      <div class="comment-content">
        <div>
          <span class="comment-content-com">{{ item.info }}</span>
          <span class="comment-time">{{ item.add_time }}</span>
          <span class="comment-reply1" @click="btninput(item.id)">回复</span>
        </div>

        <div class="comment-fabs">
          <i v-if="item.is_zan == 0" class="iconfont icon-heart" @click="btnheart(item.id, 0)"></i>
          <i v-if="item.is_zan == 1" class="iconfont icon-aixin" @click="btnheart(item.id, 1)"></i>
          <span>{{ item.zan }}</span>
        </div>
      </div>

      <!--回复评论-->
      <div class="detail-reply" @click="getDetailReply(item.id)">
        <div v-for="x in item.reply_list" :key="x.id">
          <!-- <div v-if="item.id===x.comment_id" > -->
          <div class="comment-user-2">
            <img :src="x.head_pic" />
            <span>{{ x.nickname }}</span>
          </div>

          <div class="comment-content-2">
            <div>
              <span class="comment-content-com-2">{{ x.info }}</span>
              <span class="comment-time-2">{{ x.add_time | datetimeFormat }}</span>
            </div>

            <div class="comment-fabs-2">
              <i v-if="x.is_zan == 0" class="iconfont icon-heart" @click="btnheart(x.id, 0)"></i>
              <i v-if="x.is_zan == 1" class="iconfont icon-aixin" @click="btnheart(x.id, 1)"></i>
              <span>{{ x.zan }}</span>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <!-- <div class="btn-reqly">
                    <span>展开更多回复</span>
                </div> -->
      </div>

      <div class="comment-line"></div>
    </div>

    <!--回复评论输入框-->
    <!-- <detail-reply-input @btnreplysent="btnreplysent" :nicname="nicname" :cIndex="cIndex" class="reply-input" id="reply-input"></detail-reply-input> -->
    <!--输入框背景-->
    <!-- <div class="input-back-reply" id="input-back-reply" @click="btnreplyinput"></div> -->
  </div>
</template>

<script>
// import DetailReplyInput from "./DetailReplyInput";
import { getDetailReply } from 'network/home'
import { timestampToTime } from 'common/utils'
import { zan_trade_comment } from 'network/fabs'

export default {
  // name: "supplyDetail",
  components: {
    // DetailReplyInput
  },
  data() {
    return {
      reply: {},
      cIndex: null,
      nicname: ''
    }
  },
  props: {
    id: {
      type: [String, Number]
    },
    commentList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    commentList(newValue) {
      for (let i = 0; i < newValue.length; i++) {
        newValue[i].add_time = timestampToTime(newValue[i].add_time)
      }
    }
  },
  created() {
    /*获取回复评论*/
    // this.getDetailReply()
  },
  methods: {
    btninput(id) {
      this.$emit('btninput', id)
    },
    getDetailReply(id) {
      getDetailReply(id).then((res) => {
        this.reply = res.data
      })
    },
    /*评论输入框*/
    btnreply(index, nickname) {
      this.cIndex = index
      this.nicname = nickname
      let input = document.getElementById('reply-input')
      let inputback = document.getElementById('input-back-reply')
      inputback.style.display = 'block'
      input.style.bottom = 0 + 'px'
    },
    /*收起来评论框*/
    btnreplyinput() {
      let input = document.getElementById('reply-input')
      let inputback = document.getElementById('input-back-reply')
      inputback.style.display = 'none'
      input.style.bottom = -301 + 'px'
    },

    btnreplysent() {
      // this.$router.go(0)
      let input = document.getElementById('reply-input')
      let inputback = document.getElementById('input-back-reply')
      inputback.style.display = 'none'
      input.style.bottom = -301 + 'px'
    },
    /*点赞*/
    btnheart(id, type) {
      // var message;
      /**
       * 判断点赞过后就只能取消
       */
      zan_trade_comment(id, type).then((res) => {
        if (res.data.status == 1) {
          /*更新点赞数量*/
          // if(type==0){
          //     that.commentList[index].zan = that.commentList[index].zan+1
          //     that.commentList[index].is_zan=1
          //     message = '点赞成功';
          // }else{
          //     that.commentList[index].zan = that.commentList[index].zan-1
          //     that.commentList[index].is_zan=0
          //     message = '取消点赞成功';
          // }
          this.$router.go(0)
          // this.$message(message);
        } else {
          this.$message('网络错误!')
        }
      })
    }
  }
}
</script>

<style scoped>
.detail-comment {
  width: 100%;
}
.comment-count {
  padding: 5px 10px;
  font-size: 14px;
  color: rgb(150, 150, 150);
}
.comment-item {
  padding: 5px 10px;
}

.comment-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.comment-user {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.comment-user span {
  margin-left: 8px;
  font-size: 14px;
  color: rgb(150, 150, 150);
}

.comment-content {
  margin-left: 35px;
  display: flex;
  justify-content: space-between;
}
.comment-content-com {
  font-size: 14px;
  margin-right: 5px;
}
.comment-time {
  font-size: 12px;
  color: rgb(150, 150, 150);
}
.comment-reply1 {
  font-size: 12px;
  color: rgb(100, 100, 100);
  margin-left: 20px;
}
.comment-fabs {
  color: rgb(100, 100, 100);
}

.comment-fabs span {
  font-size: 12px;
}

/*回复评论*/
.detail-reply {
  margin-left: 9.5%;
}
.detail-reply img {
  width: 20px;
  height: 20px;
}
.comment-user-2 {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.comment-user-2 span {
  margin-left: 8px;
  font-size: 13px;
  color: rgb(150, 150, 150);
}

.comment-content-2 {
  margin-left: 25px;
  display: flex;
  justify-content: space-between;
}
.comment-content-2 div {
  margin-bottom: 10px;
}
.comment-content-com-2 {
  font-size: 13px;
  margin-right: 5px;
}
.comment-time-2 {
  font-size: 12px;
  color: rgb(150, 150, 150);
}

.comment-fabs-2 {
  color: rgb(100, 100, 100);
}

.comment-fabs-2 span {
  font-size: 12px;
}

.btn-reqly {
  padding-top: 5px;
  font-size: 12px;
  color: #2e6da4;
  margin-left: 25px;
}
.comment-line {
  width: 100%;
  background-color: rgba(235, 235, 235, 3);
  height: 0.005rem;
  margin: 10px 0;
}

/*回复框*/

/*评论输入框*/
.reply-input {
  position: fixed;
  height: 300px;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: -301px;
  transition: bottom 0.4s;
}

.input-back-reply {
  position: fixed;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(40, 40, 40, 0.2);
  z-index: 9;
  transition: all 0.4s;
}
</style>
