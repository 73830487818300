import { request } from './request'

/*添加/取消信息点赞*/
export function zan_trade(t_id, type) {
  return request({
    url: '/trade/zan_trade',
    params: {
      t_id,
      type
    }
  })
}

/*添加/取消评论点赞*/
export function zan_trade_comment(t_id, type) {
  return request({
    url: '/trade/zan_trade_comment',
    params: {
      t_id,
      type
    }
  })
}
